import React from 'react';
import { Link } from '@reach/router';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import Pricing from '../components/pricing';

export default () => (
<div>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/porto.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1>Catering Menu</h1>
          </div>
          <div className='col-md-6'>
            <div className='list'>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Catering</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid black'>
        <div className='row m-10-hor'>

        <div className='col-md-5'>
            <div className='heading'>
              Dietz & Watson <span className='br'></span>Quality <span className='br'></span> Catering
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
              You spoke and we've listened. You can now order our signature Dietz & Watson Deli Sandwiches for your next event. We offer different catering packages that are ideal for any group size. Catering packages include our delightful sandwiches, soups, salads, and more sides.
            </div>
            <div className='content'>
              Get our premium deli sandwiches catered for your next event. Please provide at least 48 hour notice for large parties to ensure perfection.
            </div>
          </div>

        </div>
  </section>

  <section className='container-fluid black_more'>
    <div className='row m-10-hor'>
      <div className='col-12'>
        <div className='AppContainer'>
          <ImageGallery />
        </div>
      </div>

    </div>
  </section>
  <Pricing />
  <Footer />
</div>

);
