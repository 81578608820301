import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <section className='container-fluid black_more'>
        <div className='row m-10-hor'>

        <div className='col-12 text-center'>
          <div className='subheading'>Shop Our Catalog</div>
          <div className='heading'>Check Our Shelves</div>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <p className='content'>
                Take a minute to check out what we have in stock. Our catalog shows you what's available on our shelves. If you have any doubts you're welcome to call us to double check.
              </p>
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='pricelist cats'>
            <div className="heading">Wines</div>
            <img src='./img/wine.jpg' alt='imgbanner' className='w-100 border-radius'/>
            <ul className="list">
              <li><i className="fa fa-check"></i> Wide Variety</li>
              <li><i className="fa fa-check"></i> In Store</li>
            </ul>
            <div className='content'>
              <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=wine", "_blank")}>Shop Now </div>
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='pricelist cats'>
            <div className="heading">Bourbons</div>
            <img src='./img/bourbons.jpg' alt='imgbanner' className='w-100 border-radius'/>
            <ul className="list">
              <li><i className="fa fa-check"></i> Wide Variety</li>
              <li><i className="fa fa-check"></i> In Store</li>
            </ul>
            <div className='content'>
              <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=cognac", "_blank")}>Shop Now </div>
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='pricelist cats'>
            <div className="heading">Vodkas</div>
            <img src='./img/vodkas.jpg' alt='imgbanner' className='w-100 border-radius'/>
            <ul className="list">
              <li><i className="fa fa-check"></i> Wide Variety</li>
              <li><i className="fa fa-check"></i> In Store</li>
            </ul>
            <div className='content'>
              <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=vodka", "_blank")}>Shop Now </div>
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='pricelist cats'>
            <div className="heading">Tequilas</div>
            <img src='./img/tequila.jpg' alt='imgbanner' className='w-100 border-radius'/>
            <ul className="list">
              <li><i className="fa fa-check"></i> Wide Variety</li>
              <li><i className="fa fa-check"></i> In Store</li>
            </ul>
            <div className='content'>
              <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=tequila", "_blank")}>Shop Now </div>
            </div>
          </div>
        </div>

        </div>
        <div className='col-md-12'>
          <div className='shopAll cats'>
              <Link className='btn' to="//www.pointy.com/shops/usa/illinois/chicago/gold-coast-market">
                <span className="shine"></span>
                <span>See All Products</span>
              </Link>
            </div>
        </div>
    </section>

);
