import React from 'react';

export default () => (
  <section className='container-fluid black_more'>
        <div className='row m-10-hor'>

        <div className='col-12 text-center'>
          <div className='subheading'>Let Us Cater Your Event</div>
          <div className='heading'>Catering Menu</div>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <p className='content'>
                Curabitur mollis bibendum luctus.
                Duis suscipit vitae dui sed suscipit. Vestibulum auctor
                nunc vitae diam eleifend, in maximus metus sollicitudin.
                Quisque vitae sodales lectus. Nam porttitor justo sed mi finibus,
                vel tristique risus faucibus.
              </p>
            </div>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Small Party</div>
            <div className="bigprice"><small>starts at</small> <span className='text-gradient'>$79</span></div>
            <ul className="list">
              <li>25 Sandwiches</li>
              <li>3 Large Sides</li>
              <li>2 Large Drink</li>
              <li><i className="fa fa-check"></i> Salad Option</li>
              <li><i className="fa fa-check"></i> Utensils Incld.</li>
              <li><i className="fa fa-check"></i> Napkins Incld.</li>

            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Medium Party</div>
            <div className="bigprice"><small>starts at</small> <span className='text-gradient'>$199</span></div>
            <ul className="list">
            <li>50 Sandwiches</li>
            <li>5 Large Sides</li>
            <li>3 Large Drink</li>
            <li><i className="fa fa-check"></i> Salad Option</li>
            <li><i className="fa fa-check"></i> Utensils Incld.</li>
            <li><i className="fa fa-check"></i> Napkins Incld.</li>
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Large Party</div>
            <div className="bigprice"><small>starts at</small> <span className='text-gradient'>$249</span></div>
            <ul className="list">
            <li>100 Sandwiches</li>
            <li>10 Large Sides</li>
            <li>5 Large Drink</li>
            <li><i className="fa fa-check"></i> Salad Option</li>
            <li><i className="fa fa-check"></i> Utensils Incld.</li>
            <li><i className="fa fa-check"></i> Napkins Incld.</li>
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Custom Party</div>
            <div className="bigprice"><span className='text-gradient'>TBD</span></div>
            <ul className="list">
              <li>∞ Sandwiches</li>
              <li>∞ Large Sides</li>
              <li>∞ Large Drink</li>
              <li><i className="fa fa-check"></i> Salad Option</li>
              <li><i className="fa fa-check"></i> Utensils Incld.</li>
              <li><i className="fa fa-check"></i> Napkins Incld.</li>
            </ul>
          </div>
        </div>

        </div>
    </section>

);
