
import React from 'react';
import { Link } from '@reach/router';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
<div className='wraperitem'>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/bg-1.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1>Deli Menu</h1>
          </div>
          <div className='col-md-6'>
            <div className='list'>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Our Deli</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid' id="service">
        <div className='row m-10-hor'>
          <div className='col-md-8'>
            <div className='left'>
              <div className='mainimg'>
                <img
                    src="./img/deli.jpg"
                    alt="imgservice"
                  />
              </div>
              <div className='content'>
                <div className='title'>
                  Build <span className='color'>Your Own</span>
                </div>
                <div className='desc'>

                  <div className='row'>
                    <div className='col-md-6'>
                      <br />
                      <h4>Bread</h4>
                      <br />
                      <ul>
                        <li><h6>Hoagie Roll</h6></li>
                        <li><h6>Rustic Bread</h6></li>
                        <li><h6>Multigrain Bread</h6></li>
                        <li><h6>Ciabatta</h6></li>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <br />
                      <h4 className='color'>Cheeses</h4>
                      <br />
                      <ul>
                        <li><h6>Yellow Cheddar</h6></li>
                        <li><h6>Lacey Swiss</h6></li>
                        <li><h6>Provolone</h6></li>
                        <li><h6>Buffalo Wing Cheddar</h6></li>
                      </ul>
                    </div>
                  </div>
                  <div className='row'>
                  <div className='col-md-6'>
                  <br />

                  </div>
                    <div className='col-md-6'>
                    <br />
                    <br />
                        <h3 className='color'>Meats</h3>
                        <br />
                    </div>
                  </div>
                  <div className='row'>
                      <div className='col-md-6'>
                      <br />
                      <h5>Beef</h5>
                      <br />
                      <ul>
                        <li><h6>Medium Rare London Broil</h6></li>
                        <li><h6>Roast Beef</h6></li>
                        <li><h6>Pastrami</h6></li>
                      </ul>
                      <br />
                      <h5>Turkey</h5>
                      <br />
                      <ul>
                        <li><h6>Gourmet Lite Turkey Breast</h6></li>
                        <li><h6>Cajun Turkey Breast</h6></li>
                        <li><h6>Smoked Turkey Breast</h6></li>
                        <li><h6>Homestyle Turkey Breast</h6></li>
                      </ul>
                      </div>
                      <div className='col-md-6'>
                      <br />
                      <h5>Chicken</h5>
                      <br />
                      <ul>
                        <li><h6>Gourmet Chicken Breast</h6></li>
                        <li><h6>Buffalo Chicken Breast</h6></li>
                        <br/>
                      </ul>
                      <br />
                      <h5>Ham & Others</h5>
                      <br />
                      <ul>
                        <li><h6>Black Forest Ham</h6></li>
                        <li><h6>Hard Salami</h6></li>
                        <li><h6>Bacon</h6></li>
                      </ul>
                      <br />

                      <ul>

                      </ul>
                      </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-6'>
                      <br />
                      <h4>Veggies</h4>
                      <br />
                      <ul>
                        <li><h6>Lettuce</h6></li>
                        <li><h6>Onions</h6></li>
                        <li><h6>Tomato</h6></li>
                        <li><h6>Pickles</h6></li>
                        <li><h6>Banana Peppers</h6></li>
                        <li><h6>Roasted Red Peppers</h6></li>
                        <li><h6>Jalapeno Peppers</h6></li>
                        <li><h6>Avocado</h6></li>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <br />
                      <h4 className='color'>Sauces</h4>
                      <br />
                      <ul>
                        <li><h6>Chipotle Mayo</h6></li>
                        <li><h6>Sweet Red Pepper Mayo</h6></li>
                        <li><h6>Horseradish Sauce</h6></li>
                        <li><h6>Zesty Honey Mustard</h6></li>
                        <li><h6>Cranberry Honey Mustard</h6></li>
                        <li><h6>Ranch Dressing</h6></li>
                      </ul>
                    </div>
                  </div>


                  <br />
                  <p>We proudly feature Dietz & Watson Premium Meats and Artisan Cheeses on all our sandwiches. Every custom sandwich is served cold or hot with meats and cheeses sliced fresh to order. For Large orders please check out our catering options.</p>
                  <blockquote>
                    This market is mind blowing. Very clean location  and Their deli has the best cold cuts sandwiches. My recommendation is the pastrami on sour dough. Also their customer service is amazing such nice people and if you don’t find what you need they’ll have it ordered the next day. I’m glad Gold Coast market opened because it’s so convenient and they have all the fine wines that you won’t find at other markets. Such a wonderful place!! -Valued Customer
                  </blockquote>

                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='right'>
              <ul className='services-list'>
                <li>
                  <Link className='active' to="/deli">Build Your Own</Link>
                </li>
                <li>
                  <Link  to="/signature-sandwiches">Signature Sandwiches</Link>
                </li>
                <li>
                  <Link to="/party-platter">Party Platters</Link>
                </li>
                <li>
                  <Link to="/salads-and-sides">Sides & Salads</Link>
                </li>
              </ul>
              <div className='bannerservice'>
                <div className='imgbg'>
                  <img
                    src="./img/bg-menu.jpg"
                    alt="imgservice"
                  />
                </div>
                <div className='contb'>
                  <div className='headingb'>
                    See Full Menu
                  </div>
                  <div className='descb'>
                    Get Your Copy Now
                  </div>
                  <div className="btn" onClick={()=> window.open("./pdf/deli-menu.pdf", "_self")}>
                    <span className="shine"></span>
                    <span>Download here</span>
                  </div>
                </div>
              </div>

            </div>
            <div className='bannerubereats' onClick={()=> window.open("//www.ubereats.com/store/gold-coast-market/I6NnlAoWRvuYgpUfpSvueQ", "_self")}>
              <div className='imgbg'>
                <img
                  src="./img/uber-eats.png"
                  alt="Uber Eats Delivery"
                />
              </div>
            </div>
          </div>

        </div>
  </section>

  <Testimony />
  <Footer />
</div>

);
