import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';

const content = [
  {
    title: "We're Your Local Market",
    description:
      "We Got What You Need ",
    button: "See Our Catalog",
    link: "/catalog",
    image: "./img/home-bg.jpg"
  },
  {
    title: "Fresh Deli Sandwiches",
    description:
      "Dietz & Watson Quality",
    button: "See Deli Menu",
    link: "/deli",
    image: "./img/bg-1.jpg"
  },
  {
    title: "Premium Wine Selections",
    description:
      "Offering Wine Favorites",
    button: "See Our Wines",
    link: "//www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=wine",
    image: "./img/bg-3.jpg"
  }
];

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                  <button onClick={()=> window.open(item.link, "_self")}>
                    <span className="shine"></span>
                    <span>
                      {item.button}
                    </span>
                  </button>
                </div>
              </div>
            ))}
        </Slider>
);
