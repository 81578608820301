import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { header } from 'react-bootstrap';
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


export default function() {

    const [openMenu, setOpenMenu] = React.useState(false);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const handleBtnClick = (): void => {
      setOpenMenu(!openMenu);
    };
    const handleBtnClick1 = (): void => {
      setOpenMenu1(!openMenu1);
    };
    const closeMenu = (): void => {
      setOpenMenu(false);
    };
    const closeMenu1 = (): void => {
      setOpenMenu1(false);
    };
    const ref = useOnclickOutside(() => {
      closeMenu();
    });
    const ref1 = useOnclickOutside(() => {
      closeMenu1();
    });

    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky + 300) {
          header.classList.add("sticky");
          totop.classList.add("show");

        } else {
          header.classList.remove("sticky");
          totop.classList.remove("show");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return (
    <header id="myHeader" className='navbar'>
     <div className='container-fluid'>
       <div className='row m-2-hor w-100-nav'>
          <div className='logo'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                <img
                    src="./img/logo.png"
                    className="img-fluid"
                    alt="#"
                  />
              </NavLink>
            </div>
            <div className='header-btn'>
              <div class="link" onClick={() => window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market", "_blank")}>Browse Catalog </div>
              </div>
            </div>

            <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu &&
                  <div className='menu'>
                    <div className='navbar-item'>
                      <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                        Home
                        </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                        About
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/catalog" onClick={() => btn_icon(!showmenu)}>
                        Our Catalog
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                        <div className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick1}
                          >
                          Our Deli
                        </div>
                        {openMenu1 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/deli" onClick={() => btn_icon(!showmenu)}>Build Your Own</NavLink>
                              <NavLink to="/signature-sandwiches" onClick={() => btn_icon(!showmenu)}>Signature Sandwiches</NavLink>
                              <NavLink to="/party-platter" onClick={() => btn_icon(!showmenu)}>Party Platters</NavLink>
                              <NavLink to="/salads-and-sides" onClick={() => btn_icon(!showmenu)}>Sides & Salads</NavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/*<div className='navbar-item'>
                      <NavLink to="/catering" onClick={() => btn_icon(!showmenu)}>
                        Catering
                      </NavLink>
                    </div>*/}
                    <div className='navbar-item'>
                      <NavLink to="/contact" onClick={() => btn_icon(!showmenu)}>
                        Contact
                      </NavLink>
                    </div>
                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>

                    <div className='navbar-item'>
                      <NavLink to="/">Home</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/about">About</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/catalog">Our Catalog</NavLink>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                          <div className="dropdown-custom dropdown-toggle btn"
                             onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                            Our Deli
                            {openMenu1 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/deli">Build Your Own</NavLink>
                              <NavLink to="/signature-sandwiches">Signaure Sandwiches</NavLink>
                              <NavLink to="/party-platter">Party Platters</NavLink>
                              <NavLink to="/salads-and-sides">Sides & Salads</NavLink>
                              </div>
                            </div>
                          )}
                          </div>

                        </div>
                    </div>
                  {/*<div className='navbar-item'>
                      <NavLink to="/catering">Catering</NavLink>
                    </div>*/}
                    <div className='navbar-item'>
                      <NavLink to="/contact">Contact</NavLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>

      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>
    </header>
    );
}
