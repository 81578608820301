import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <section className='container-fluid p-md-0'>
        <div className='row'>

          <div className='col-md-6'>
            <img src='./img/deli-banner.jpg' alt='imgbanner' className='w-100 border-radius'/>
          </div>
          <div className='col-md-6 centered'>
            <div>
              <div className="subheading mt-md-0 mt-5">
                You've Probably Heard About
              </div>
              <div className="heading">
                Our Deli Sandwiches
              </div>
              <p>
                They're a local favorite and what everyone in town is talking about. Our Signature Sandwiches use premium Dietz & Watson Deli Meats and Cheeses. Always prepared fresh and made with love. If you haven't already stop in today to try one of our signature deli sandwiches. Now available for order through UberEats.
                </p>
                <p>
                <span className='br'></span><strong>First sandwich? Mention it in store for 10% off.</strong>
                <p>&nbsp;</p>
               </p>

              <Link className='btn' to="/deli">
                <span className="shine"></span>
                <span>See Menu</span>
              </Link>&nbsp;&nbsp;&nbsp;
              <Link className='btn' to="//www.ubereats.com/store/gold-coast-market/I6NnlAoWRvuYgpUfpSvueQ?">
                <span className="shine"></span>
                <span>Order Now</span>
              </Link>
            </div>
          </div>

        </div>
    </section>
);
