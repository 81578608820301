import React from 'react';
import { Link } from '@reach/router';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
<div className='wraperitem'>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/about.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1>About</h1>
          </div>
          <div className='col-md-6'>
            <div className='list'>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>About</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid black_more'>
        <div className='row m-10-hor'>

        <div className='col-md-5'>
            <div className='heading'>
              Born From<span className='br'></span>And For Our<span className='br'></span> Community
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
              Gold Coast Market was born to serve our local community. We're committed to always stocking your favorites including fresh sandwiches, premium wines, and allocated liquors.

              We take pride in offering a friendly and welcoming shopping experience.
            </div>
            <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Quisque vitae sodales lectus,
              vel tristique risus faucibus.
            </div>
          </div>

        </div>
  </section>

  <section className='container-fluid p-0'>
        <div className='row'>

          <div className="col-md-6 px-0">
            <img src="./img/history.jpg" className="imgslickz" alt="#"/>
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div className="p-md-5">
              <div className="subheading">March 1, 2021</div>
              <div className="heading">
                Our Grand Debute
              </div>
              <p className="mt-3">
                We had our grand opening in the beginning of 2021 and we're thrilled to be serving the River North and Gold Coast community. The beginning of what we consider to be a
              </p>
              <Link className='btn' to="">
                <span className="shine"></span>
                <span>More Detail</span>
              </Link>
            </div>
          </div>

          <div className="col-md-6 centered p-md-5 pt-5 pb-5">
            <div className="p-md-5">
              <div className="subheading">On Our Shelves</div>
              <div className="heading">
                Shop Our Catalog
              </div>
              <p className="mt-3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmodt temp to the incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis a
                nostr a exercitation ullamco laboris nisi ut aliquip.
              </p>
              <Link className='btn' to="/catalog">
                <span className="shine"></span>
                <span>Store Catalog</span>
              </Link>
            </div>
          </div>

          <div className="col-md-6 px-0">
            <img src="./img/shop-catalog.jpg" className="imgslickz" alt="#"/>
          </div>

        </div>
  </section>

  <Testimony />
  <Footer />
</div>

);
