
import React from 'react';
import { Link } from '@reach/router';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
<div className='wraperitem'>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/bg-1.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1>Salads & Sides</h1>
          </div>
          <div className='col-md-6'>
            <div className='list'>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Our Deli</span>
              <span className='dash'>/</span>
              <span>Salads & Sides</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid' id="service">
        <div className='row m-10-hor'>
          <div className='col-md-8'>
            <div className='left'>
              <div className='mainimg'>
                <img
                    src="./img/party-platter.jpg"
                    alt="imgservice"
                  />
              </div>
              <div className='content'>
                <div className='title'>
                  <span className='color'>Salads</span> & Sides
                </div>
                <div className='desc'>
                  <p>An international firm of architects, planners and interior designers specialising in a wide range of commercial, residential and public sector projects.</p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p><span className='title'>Oak<br />Street...............</span><span className='color'>$8.99</span></p>
                      <p>Medium Rare London Broil Roast Beef, Yellow Cheddar Cheese, Lettuce, Tomato, Onion, Pickles and Horseradish Sauce on Rustic Thick-Sliced Bread</p>
                    </div>
                    <div className='col-md-6'>
                    <p><span className='title'>Division Street...............</span><span className='color'>$7.99</span></p>
                    <p>Smoked Turkey Breast with Buffalo Cheddar Cheese, Lettuce, Tomato, Onion, Jalapeno Peppers and Chipotle Mayo on Ciabatta</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Chicago Avenue.........</span><span className='color'>$7.99</span></p>
                      <p>Black Forest Ham, Hard Salami, Provolone Cheese, Lettuce, Tomato, Onion, Banana Peppers and Roasted Red Peppers on Ciabatta</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Rush Street...............</span><span className='color'>$7.99</span></p>
                      <p>Gourmet Chicken Breast with Bacon (optional), Yellow Cheddar Cheese, Lettuce, Tomato, Onion, Avocado, Ranch Dressing on Ciabatta</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Walton Street...............</span><span className='color'>$7.99</span></p>
                      <p>Black Forest Ham with Lacey Swiss Cheese, Lettuce, Tomato, Onion and Honey Mustard on Rustic Thick-Sliced Bread</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>State Street...............</span><span className='color'>$7.99</span></p>
                      <p>Black Forest Ham, Gourmet Lite Turkey Breast, Bacon with Yellow Cheddar Cheese, Lettuce, Tomato, Onion, Pickles, and Sweet Red Pepper Mayo on Rustic Bread</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Elm<br /> Street...............</span><span className='color'>$7.99</span></p>
                      <p>Cajun Turkey Breast and Bacon with Buffalo Wing Cheddar Cheese, Lettuce, Tomato, Onion, Pickles and Cajun Mayo on Ciabatta</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Chestnut Street...............</span><span className='color'>$7.99</span></p>
                      <p>Gourmet Lite Turkey Breast with Lacey Swiss, Spinach, Tomato, and Pickles on Multigrain Bread</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Maple Street...............</span><span className='color'>$7.99</span></p>
                      <p>Buffalo Chicken Breast with Yellow Cheddar Cheese, Lettuce, Tomato, Onion, Pickles, and Chipotle Mayo on Multigrain Bread</p>
                    </div>
                    <div className='col-md-6'>
                      <p><span className='title'>Seasonal Special............</span><span className='color'>$7.99</span></p>
                      <p>Homestyle Turkey Breast with Lacey Swiss Cheese, Lettuce, Tomato, Onion, and Cranberry Mustard on Multigrain Bread</p>
                    </div>
                  </div>
                  <br />
                  <p>We proudly feature Dietz & Watson Premium Meats and Artisan Cheeses on all our sandwiches. Every custom sandwich is served cold or hot with meats and cheeses sliced fresh to order. For Large orders please check out our catering options.</p>
                  <blockquote>
                    This market is mind blowing. Very clean location  and Their deli has the best cold cuts sandwiches. My recommendation is the pastrami on sour dough. Also their customer service is amazing such nice people and if you don’t find what you need they’ll have it ordered the next day. I’m glad Gold Coast market opened because it’s so convenient and they have all the fine wines that you won’t find at other markets. Such a wonderful place!! -Valued Customer
                  </blockquote>

                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='right'>
              <ul className='services-list'>
                <li>
                  <Link to="/deli">Build Your Own</Link>
                </li>
                <li>
                  <Link to="/signature-sandwiches">Signature Sandwiches</Link>
                </li>
                <li>
                  <Link to="/party-platter">Party Platters</Link>
                </li>
                <li>
                  <Link className='active' to="/salads-and-sides">Sides & Salads</Link>
                </li>

              </ul>
              <div className='bannerservice'>
                <div className='imgbg'>
                  <img
                    src="./img/bg-menu.jpg"
                    alt="imgservice"
                  />
                </div>
                <div className='contb'>
                  <div className='headingb'>
                    See Full Menu
                  </div>
                  <div className='descb'>
                    Get Your Copy Now
                  </div>
                  <div className="btn" onClick={()=> window.open("./pdf/deli-menu.pdf", "_self")}>
                    <span className="shine"></span>
                    <span>Download here</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='bannerubereats' onClick={()=> window.open("//www.ubereats.com/store/gold-coast-market/I6NnlAoWRvuYgpUfpSvueQ", "_self")}>
              <div className='imgbg'>
                <img
                  src="./img/uber-eats.png"
                  alt="Uber Eats Delivery"
                />
              </div>
            </div>
          </div>
        </div>
  </section>

  <Testimony />
  <Footer />
</div>

);
