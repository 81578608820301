import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Loader from './menu/loader';
import Header from './menu/header';
import Home from './pages/home';
import Deli from './pages/deli';
import Sides from './pages/salads-and-sides';
import PartyPlatter from './pages/party-platter';
import SignatureSandwiches from './pages/signature-sandwiches';
import Catalog from './pages/catalog';
import Catering from './pages/catering';
import Home2 from './pages/home2';
import About from './pages/about';
import Team from './pages/team';
import Price from './pages/price';
import Works from './pages/works';
import Service from './pages/service';
import Service1 from './pages/service1';
import Service2 from './pages/service2';
import Service3 from './pages/service3';
import Blog from './pages/blog';
import Contact from './pages/contact';

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id='routerhang'>
        <div key={location.key}>
        <Loader/>
          <Router location={location}>
            {children}
          </Router>
        </div>
      </div>
    )}
  </Location>
);

export default () => (
  <div className="wraper">

    <Header/>
      <PosedRouter>
      <ScrollTop path="/">
        <Home exact path="/">
          <Redirect to="/home" />
        </Home>
        <Deli path="/deli" />
        <PartyPlatter path="/party-platter" />
        <SignatureSandwiches path="/signature-sandwiches" />
        <Sides path="/salads-and-sides" />
        <Catalog path="/catalog" />
        <Catering path="/catering" />
        <Home2 path="/home2" />
        <About path="/about" />
        <Team path="/team" />
        <Price path="/price" />
        <Works path="/works" />
        <Service path="/service" />
        <Service1 path="/service1" />
        <Service2 path="/service2" />
        <Service3 path="/service3" />
        <Blog path="/blog" />
        <Contact path="/contact" />
        </ScrollTop>
      </PosedRouter>
    <ScrollToTopBtn />

  </div>
);
