import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
    <img src='./img/footer-img.jpg' alt='bg-footer'/>
  </div>
    <div className='row m-10-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='footer-logo'>
              <Link to="/">
                <img
                  src="./img/logo.png"
                  className="img-fluid"
                  alt="#"
                />
              </Link>
              <br /><br />
          </div>
          <div className='content'>
             <p>We're a local market and deli that offers you a personalized shopping experience along with your favorites.</p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:info@goldcoastmarket.com", "_self")}>Email at. info@goldcoastmarket.com</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Resources
          </div>
          <div className='content'>
            <div className='link'>Store Catalog</div>
            <div className='link'>Deli Menu</div>
            <div className='link'>Catering Menu</div>
            <div className='link'>Contact Us</div>
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            In Stock
          </div>
          <div className='content'>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=tequila", "_blank")}>Tequilas</div>

             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=bourbon", "_blank")}>Bourbons</div>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=vodka", "_blank")}>Vodkas</div>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=cognac", "_blank")}>Cognac </div>
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
          <br />
          </div>
          <div className='content'>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=wine", "_blank")}>Wines</div>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=whiskey", "_blank")}>Whiskeys</div>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=seltzer", "_blank")}>Seltzers</div>
             <div className='link' onClick={()=> window.open("https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market?query=beer", "_blank")}>Beer </div>
          </div>
        </div>
      </div>

      <div className='col-md-2'>
        <div className='footer-col'>

          <div className='content'>

            <div className='socialicon' onClick={()=> window.open("https://facebook.com/", "_self")}>
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i>
            </div>
            <div className='socialicon' onClick={()=> window.open("https://linkedin.com/", "_self")}>
            <span className="shine"></span>
              <i className="fa fa-linkedin"></i>
            </div>
            <div className='socialicon'onClick={()=> window.open("https://twitter.com/", "_self")}>
            <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div>
            <div className='socialicon' onClick={()=> window.open("https://instagram.com/", "_self")}>
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-10-hor'>
        <div className='col-md-6'>
          <div className='content'><span className='font-weight-bold'>Gold Coast Market</span> © 2021. All rights reserved. <br/>Powered by <a href="https://ux.haus" target="_blank">ux.haus</a></div>
        </div>
      </div>
    </div>
  </footer>

);
