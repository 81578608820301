import React from 'react';
import { Link } from '@reach/router';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';
import CatalogCats from '../components/catalog-cats';


export default () => (
<div className='wraperitem'>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/catalog.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1>Our Catalog</h1>
          </div>
          <div className='col-md-6'>
            <div className='list'>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Catalog</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 <CatalogCats />
  <div className='container-fluid black_more'>
        <div className='row'>

      <iframe src="https://www.pointy.com/shops/usa/illinois/chicago/gold-coast-market" height="860" width="100%"></iframe>


        </div>
  </div>

  <Footer />
</div>

);
