import React from 'react';
import Slider from '../components/sliderhome';
import Carouselfeature from '../components/carouselfeature';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';
import CatalogCats from '../components/catalog-cats';

export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
          <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div>
      </section>

      <section className='container-fluid p-0'>
        <div className='row'>

        <div className='col-md-4 p-0'>
          <div className='features'>
            <div className='bg'>
              <img
                  src="./img/bg-serv-2.jpg"
                  alt="bg-features"
                />
            </div>
            <div className='content'>
              <div className='heading'>
                Fresh Deli Sandwiches
              </div>
              <div className='con-text'>
                Our deli sandwiches are served daily and always made fresh. Offering Dietz and Watson meats with our signature sandwich menu you won't be let down.
              </div>
              <div className='link'>
                <span className="shine"></span>
                View Deli Menu
              </div>
            </div>
          </div>
        </div>
          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-1.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Premium Liquor
                </div>
                <div className='con-text'>
                  We offer premium liqour selections with pre-order options on your favorite items. If what you're looking for isn't in stock let us know and we'll stock it.
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Shop Catalog
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-3.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Sandwich Catering
                </div>
                <div className='con-text'>
                  If you're planning an event and need catering you can now order our premium Dietz & Watson deli sandwiches for your next catered event.
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  View Catering Menu
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor'>

          <div className='col-md-5'>
            <div className='heading'>
              We're <span className='br'></span>Committed<span className='br'></span>
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
              We strive to offer you a local shopping experience unlike others.
              By offering your favorites year-round. We're committed to stocking
              premium wine and liquor including top shelf and allocated selections.
              If we don't carry your favorites, let us know and we'll be sure to start stocking them.
            </div>
            <div className='content'>
              Our promise is to personalize your shopping experience at Gold Coast Market by prioritizing the following:
            </div>
          </div>

        </div>
    </section>

    <section className='container-fluid pt-0 black'>
        <div className='row m-10-hor'>
         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              01
            </div>
            <div className='heading'>
              SPECIAL ORDERS
            </div>
            <div className='content'>
              Looking for something specific? Let us know we'll make sure to stock it for you, including limited selections.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              02
            </div>
            <div className='heading'>
              FINE SERVICE
            </div>
            <div className='content'>
              Striving to offer you the best in-store shopping experience with high quality customer service.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              03
            </div>
            <div className='heading'>
              ALLOCATED PRODUCTS
            </div>
            <div className='content'>
            We stock allocated bottles that could be hard to come across (psst great collectables and gifts).
            </div>
          </div>
         </div>

        </div>
    </section>

    <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Talk of the Town</div>
            <div className='heading'>Featured Drinks</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <p className='content'>
                  At Gold Coast Market we're always listening to what our customers want. We have tailored a fine selection of featured items. Here's our curated list of our most popular beverages, chosen by you.

                </p>
              </div>
            </div>
          </div>
          <Carouselfeature />
        </div>
    </section>
    <CatalogCats />
    <Bannercontact/>
    <Pricing />
    <Testimony />
    <Footer />

  </div>
);
